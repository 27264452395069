import { graphql } from "gatsby"

export const PageInfoFragment = graphql
  `
fragment MediaItemsFragment on UmbracoContentProperties {
  mediaItems {
    url
    crops {
      alias
      cropUrl
    }
    focalPoint {
      top
      left
    }
  }
}

fragment LinksFragment on UmbracoContentProperties {
  links {
    name
    url
  }
}

fragment NestedElementsFragment on UmbracoContentProperties {
  elements {
    properties {
      elements {
        properties {
          elements {
            properties {
              alias
              text
              richText
              mediaItems {
                url
                crops {
                  alias
                  cropUrl
                }
                focalPoint {
                  top
                  left
                }
              }
              links {
                name
                url
              }
              dateTime
            } 
          }
          alias
          text
          richText
          mediaItems {
            url
            crops {
              alias
              cropUrl
            }
            focalPoint {
              top
              left
            }
          }
          links {
            name
            url
          }
          dateTime
        } 
      }
      alias
      text
      richText
      mediaItems {
        url
        crops {
          alias
          cropUrl
        }
        focalPoint {
          top
          left
        }
      }
      links {
        name
        url
      }
      dateTime
    } 
  }
}

fragment PropertyFragment on UmbracoContentProperties {
  alias
  text
  richText
  dateTime
  ...MediaItemsFragment
  ...LinksFragment
  ...NestedElementsFragment
}

fragment PageInfoFragment on UmbracoContent {
  url
  contentId
  contentName
  updateDate
  properties {
    ...PropertyFragment
  }    
}
`

function coalescePropertyValue(obj: any) {
  return obj.text ?? obj.richText ?? obj.mediaItems ?? obj.links ?? obj.dateTime ??
    (
      !!obj.elements ?
        obj.elements.map((elem: any) => readNestedElementProperties(elem)) :
        undefined
    )
}

function readProperties(propsList: any[]): any {
  return Object.fromEntries(propsList.map((obj: any, i: any): any => {
    let propValue = coalescePropertyValue(obj)
    return [obj.alias, propValue]
  }))
}

function readNestedElementProperties(elem: any): any {
  let keyValuesNamed = Object.keys(elem).reduce((acc: any[], key: string) => {
    if (key != "properties") {
      let propertyAlias = key
      let propObj = elem[propertyAlias]
      let propValue = propObj == undefined ? undefined : coalescePropertyValue(propObj)
      acc.push([propertyAlias, propValue])
    }
    return acc
  }, [])
  const propsKeyValuesNamed = Object.fromEntries(keyValuesNamed)
  let propsList = elem.properties
  let propsKeyValues = propsList ? Object.fromEntries(propsList.map((obj: any, i: any): any => {
    let propValue = coalescePropertyValue(obj)
    return [obj.alias, propValue]
  })) : {}
  return { ...propsKeyValuesNamed, ...propsKeyValues }
}

export function readPageTitle(data: any): any {
  return data?.umbracoContent?.contentName ?? ""
}

export function readContentProperties(umbracoContent: any, namedProperties: string[] | undefined = undefined): any {
  if (!umbracoContent) return
  const propsList = umbracoContent.properties
  // Detect single properties
  if (namedProperties == undefined) {
    if (propsList == undefined) {
      // Find members that look like single properties
      namedProperties = Object.keys(umbracoContent).filter((key: string) => {
        const obj = umbracoContent[key]
        return (obj && (obj.text || obj.richText || obj.mediaItems || obj.links || obj.elements || obj.dateTime)) ? true : false
      })
    }
  }
  const propsKeyValuesNamed = namedProperties ? Object.fromEntries(namedProperties.map(propertyAlias => {
    let propObj = umbracoContent[propertyAlias]
    let propValue = propObj == undefined ? undefined : coalescePropertyValue(propObj)
    return [propertyAlias, propValue]
  })) : {}
  const propsKeyValues = propsList ? readProperties(propsList) : {}
  return { ...propsKeyValuesNamed, ...propsKeyValues }
}


export function readPageProperties(data: any, namedProperties: string[] | undefined = undefined): any {
  return readContentProperties(data.umbracoContent, namedProperties)
}
