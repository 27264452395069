import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import './Modal.scss';
import { Button } from '@components/button/Button';
import useWindowSize from '@utils/WindowSize';
import { RxCross2 } from "react-icons/rx"


interface IModal {
    open: any;
    onOpenChange?: any;
    onOk?: () => void;
    okText?: string;
    closable?: boolean;
    children?: React.ReactNode;
    okIntent?: "primary" | "secondary" | "tertiary";
    cancelIntent?: "primary" | "secondary" | "tertiary";
    cancelText?: string;
    onCancel?: any;
    className?: string;
}

const Modal = (props: IModal) => {
    let { open, onOpenChange, children, okText, onOk, cancelText, onCancel, className, okIntent = "primary", cancelIntent = "secondary", closable } = props
    const { width } = useWindowSize()
    
    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            <Dialog.Portal>
                <Dialog.Overlay className="DialogOverlay" />
                <Dialog.Content className={`DialogContent ${!okText ? "no-footer" : ""} ${className}`}>
                    {children}

                    {(okText || cancelText) && 
                    <div className='DialogFooter'>
                    
                        {okText && <Button intent={okIntent} fullWidth={width < 1024 ? true : false} onClick={onOk}>{okText}</Button>}
                        
                        {cancelText && <Button intent={cancelIntent} fullWidth={width < 1024 ? true : false} onClick={onCancel}>{cancelText}</Button>}

                    </div>}
                    {closable && <Dialog.Close asChild>
                        <button className="IconButton" aria-label="Close">
                            <RxCross2 />
                        </button>
                    </Dialog.Close>}
                </Dialog.Content>

            </Dialog.Portal>
        </Dialog.Root>
    )
};

export default Modal;