import * as Label from "@radix-ui/react-label"
import React, { useId, forwardRef, useState } from "react"
import "./Input.scss"
import { BiSearch } from "react-icons/bi"
import { LocationIcon } from "@assets/icons"

export type InputType =
  | "text"
  | "email"
  | "number"
  | "subscription"
  | "textarea"
export interface InputProps {
  label: string
  type?: InputType
  placeholder?: string
  className?: string
  error?: string | boolean | undefined | null
  children?: React.ReactNode
  icon?: any
  datalist?: boolean
  options?: any[]
  onWheel?: any
  min?: any
  max?: any
  charLength?: string
  onKeyDown?: any
  onChange?: any
  value?: any
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ ...props }: any, ref) => {
    const {
      label,
      error,
      name,
      type,
      valueChecker,
      value,
      icon,
      options,
      onWheel,
      min,
      charLength,
      max,
      onKeyDown,
      onChange,
    } = props
    const id = useId()
    
    return (
      <div className={`form-group ${error && "input-error"}`}>
        {type === "textarea" ? (
          <textarea
            placeholder=" "
            className={`Input ${error && "input-error"} ${
              icon === "location"
                ? "location-input"
                : icon === "search"
                ? "search-input"
                : ""
            }`}
            type={type}
            id={id}
            name={name}
            ref={ref}
            {...props}
            onWheel={onWheel}
            min={min}
          />
        ) : (
          <input
            placeholder=" "
            className={`Input ${error && "input-error"} ${
              icon === "location"
                ? "location-input"
                : icon === "search"
                ? "search-input"
                : ""
            }`}
            type={type}
            id={id}
            name={name}
            ref={ref}
            value={value}
            {...props}
            onWheel={onWheel}
            min={min}
            onKeyDown={onKeyDown}
            onChange={onChange}
          />
        )}
        {icon === "location" ? (
          <LocationIcon className="location-icon" />
        ) : icon === "search" ? (
          <BiSearch className="search-icon" />
        ) : null}
        <Label.Root
          className={`LabelRoot ${
            icon === "location"
              ? "location-input"
              : icon === "search"
              ? "search-input"
              : ""
          }`}
          htmlFor={id}
        >
          {label}
        </Label.Root>
        {type === "textarea" && (
          <p className="charLength">{`${
            charLength ? charLength?.length : 0
          }/${max}`}</p>
        )}
        {error && <span className="errorMessage">{error}</span>}
      </div>
    )
  }
)
export default Input
