import { Link, navigate, withPrefix } from "gatsby"
import React from "react"

const Navigate = ({ href, children, ...props }: any) => {
  return (
    <Link onClick={() => navigate(withPrefix(href))} {...props}>
      {children}
    </Link>
  )
}

export default Navigate
