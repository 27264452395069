import React, { useState, useRef, useEffect } from 'react'
import "./SurveyModal.scss"
import Modal from '@components/modal/Modal'
import Input from '@components/input/Input'
import { AiFillStar } from "react-icons/ai"
import StarsRating from 'react-star-rate';
import { Button } from '@components/button/Button'
import Slider from "react-slick"


const SurveyModal = ({ pageProperties }: any) => {
    const [open, setOpen] = useState(true)
    const [value, setValue] = useState(0);
    const sliderRef = useRef<any>(null)
    const [feedback, setFeedback] = useState<any>(null)


    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        rows: 1,
        arrows: false,
        centerPadding: "30px",
    }


    const goToNextSlide = () => {
        sliderRef.current.slickNext();
    };

    useEffect(() => {
        if (value !== 0) {
            goToNextSlide()
        }
    }, [value])

    const data = {
        stars: value,
        feedback: feedback
    }


    const handleSubmit = async () => {
        setOpen(false)
    }

    return (
        <Modal open={open} onOpenChange={setOpen} closable>
            <div className='survey-slider'>
                <Slider {...settings} ref={sliderRef}>
                    <div className='survey-modal'>
                        <p className='survey-question'>{pageProperties?.satisfactionQuestion || "How satisfied are you with our new website?"}</p>
                        <StarsRating
                            tabIndex={-1}
                            value={value}
                            onChange={(value: any) => {
                                setValue(value);
                            }}
                            autoFocus={false}
                            allowHalf={false}
                            classNamePrefix='star-ratings'
                            symbol={<AiFillStar />}
                        />
                    </div>
                    <div className='survey-modal'>
                    <p className='survey-question'>{value === 1 || value === 2 ? pageProperties?.poorReviewQuestion || "How may we improve?" : pageProperties?.goodReviewQuestion || "What do you like the most?"}</p>
                        <Input label='Write your answer here' type='text' onChange={(e: any) => setFeedback(e.target.value)} />
                        <Button fullWidth disabled={!feedback || value === 0} onClick={handleSubmit}>{pageProperties?.submitButtonText || "Submit"}</Button>
                    </div>
                </Slider>
            </div>
        </Modal>
    )
}

export default SurveyModal