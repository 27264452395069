import React from 'react'
import "./Spinner.scss"
import { Props as ButtonProps } from '../button/Button'
import { cva, VariantProps } from "class-variance-authority"



const spinnerStyles = cva(
    'spinner',
    {
        variants: {
            intent: {
                primary: 'primary',
                secondary: 'secondary',
                tertiary: 'tertiary',
                quaternary: 'quaternary',
                white: 'white',
                blue: 'blue'
            },
        },
        defaultVariants: {
            intent: 'primary',
        },
    },
);

export interface Props extends VariantProps<typeof spinnerStyles> { }

const Spinner = ({ intent, ...props }: ButtonProps) => {
    return (
        <svg className={spinnerStyles({ intent })} viewBox="25 25 50 50">
            <circle r="20" cy="50" cx="50"></circle>
        </svg>
    )
}

export default Spinner