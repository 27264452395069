import React, { useState, useEffect } from 'react';
import "./WelcomeModal.scss";
import { StaticImage } from "gatsby-plugin-image";
import useCookie from 'src/shared/utils/UseCookie';
import Modal from '@components/modal/Modal';

type TWelcomeModal = {
    welcomeTitle: string,
    welcomeDescription: string,
    welcomeListTitle: string,
    welcomeListElements: string[],
    welcomeButtonName: string,
    hideWelcomeModal: string,
}

const WelcomeModal = ({ pageProperties }: any) => {
    const [welcomeModalDisplay, setWelcomeModalDisplay]: any = useState(false);
    const psaccCookie = useCookie("psacc-welcome-modal", "true", { path: '/' });

    // Static data
    const config: TWelcomeModal = {
        welcomeTitle: "Welcome to our new websites!!",
        welcomeDescription: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione enim porro necessitatibus necessitatibus necessitatibus necessitatibus repudiandae amet nam voluptates dolor dolorum dolorem a illum nemo rerum necessitatibus TEST",
        welcomeListTitle: "List title",
        welcomeListElements: ["Sample 1", "Sample 2", "Sample 3", "Sample 4", "Sample 5"],
        welcomeButtonName: "Button Name",
        hideWelcomeModal: "true",
    }

    if (!!pageProperties) {
        config.welcomeTitle = pageProperties?.welcomeTitle;
        config.welcomeDescription = pageProperties?.welcomeDescription;
        config.welcomeListTitle = pageProperties?.welcomeListTitle;
        config.welcomeListElements = pageProperties?.welcomeListElements?.map((val: any) => {
            return val.listItem;
        })
        config.welcomeButtonName = pageProperties?.welcomeButtonName;
        config.hideWelcomeModal = pageProperties?.hideWelcomeModal;
    }

    useEffect(() => {
        if (psaccCookie[0] == undefined) {
            psaccCookie[1]("true");
            setWelcomeModalDisplay(true);
        } else if (psaccCookie[0] == "true") {
            setWelcomeModalDisplay(true);
        } else if (psaccCookie[0] == "false") {
            setWelcomeModalDisplay(false);
        }
    }, []);


    // Trigger when experience the beta site button clicked
    const welcomeModalHandler = () => {
        setWelcomeModalDisplay(false);

        const welcomeModalExpires = () => {
            const date = new Date(Date.now() + 2592000);
            date.setFullYear(date.getFullYear() + 1);
            return date;
        }

        psaccCookie[1]("false", { expires: welcomeModalExpires() });

    }


    return (
        <Modal open={welcomeModalDisplay && config.hideWelcomeModal !== "true"} onOk={welcomeModalHandler} okText={config.welcomeButtonName} onOpenChange={setWelcomeModalDisplay}>
            <div className="welcome-modal">
                <h1>{config.welcomeTitle} <StaticImage src="../../../assets/images/arrow-top-right.png" alt="Arrow Icon" /></h1>

                <div dangerouslySetInnerHTML={{ __html: config.welcomeDescription }} />

                <div className="welcome-modal-list">
                    <span>{config.welcomeListTitle}: </span>
                    <ul>
                        {
                            config?.welcomeListElements?.map((val: any, index: any) => {
                                return <li key={"welcome-list-" + index}>{val}</li>
                            })
                        }
                    </ul>
                </div>
            </div>
        </Modal>
    )

}

export default WelcomeModal;
