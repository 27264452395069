import React from "react"
import Navigate from "@utils/Navigate"

export type InputType = "button" | "submit"
export interface Props {
  href?: string
  className?: any
  disabled?: boolean
  onClick?: () => void
  children?: React.ReactNode
  target?: string
  rel?: string
  type?: InputType
}

export function ButtonOrLink({
  href,
  target,
  children,
  onClick,
  disabled,
  type,
  ...props
}: Props) {
  if (href) {
    return (
      <Navigate href={href} to={href} target={target} {...props}>
        {children}
      </Navigate>
    )
  }
  return (
    <button type={type} onClick={onClick} disabled={disabled} {...props}>
      {children}
    </button>
  )
}
