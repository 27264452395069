import React from 'react';
import { ButtonOrLink, Props as ButtonOrLinkProps } from './ButtonOrLink';
import "./Button.scss"
import { cva, VariantProps } from 'class-variance-authority';
import Spinner from '../spinner/Spinner';
import { MdKeyboardArrowRight } from 'react-icons/md'

const buttonStyles = cva(
	'button',
	{
		variants: {
			intent: {
				primary: 'primary',
				secondary: 'secondary',
				primaryOutlined: 'primary-outlined',
				primaryTextButton: 'primary-text-button',
				secondaryTextButton: 'secondary-text-button',
				tertiary: "tertiary",
				iconOnly: "icon-only"
			},
			fullWidth: {
				true: 'full-width',
			},
			size: {
				small: "small",
				medium: "medium",
				large: "large",
			},
			loading: {
				true: "loading"
			},

			withIcon: {
				true: "with-icon"
			},
			iconOnly: {
				true: "icon-only",
			}
		},
		defaultVariants: {
			intent: "primary",
			size: "medium"
		},
	},
);

export interface Props
	extends ButtonOrLinkProps,
	VariantProps<typeof buttonStyles> { }

export function Button({ intent, fullWidth, size, children, loading, withIcon, iconOnly, ...props }: Props) {
	return (
		<ButtonOrLink className={buttonStyles({ intent, fullWidth, size, withIcon, iconOnly, })} {...props}>{loading ? <Spinner intent={intent} /> : children} {withIcon ? <MdKeyboardArrowRight className='button-icon' /> : null}</ButtonOrLink>
	);
}
