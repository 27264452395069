import React from "react"
import type { GatsbyBrowser } from "gatsby"
import Layout from "./src/shared/core/layout/Layout"
import "./src/styles/global.scss"
import "react-datepicker/dist/react-datepicker.css";



export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <Layout>{element}</Layout>
}
