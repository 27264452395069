import { Slice, useStaticQuery, graphql } from "gatsby"
import React from "react"
import "./Layout.scss"
import CookieConsent from "react-cookie-consent"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"
import { readPageProperties } from "@core/pageInfo/PageInfo"
import WelcomeModal from "@core/welcome-modal/WelcomeModal"
import SurveyModal from "@core/survey-modal/SurveyModal"

export default function Layout({ children }: any) {
  const data = useStaticQuery(graphql`
    query {
      umbracoContent(url: { eq: "/" }) {
        cookiePolicy: property(alias: "cookieToggle") {
          ...PropertyFragment
        }
        cookieDescription: property(alias: "description") {
          ...PropertyFragment
        }
        welcomeTitle: property(alias: "welcomeTitle") {
          ...PropertyFragment
        }
        welcomeDescription: property(alias: "welcomeDescription") {
          ...PropertyFragment
        }
        welcomeListTitle: property(alias: "welcomeListTitle") {
          ...PropertyFragment
        }
        welcomeListElements: property(alias: "welcomeListElements") {
          ...NestedElementsFragment
        }
        welcomeButtonName: property(alias: "welcomeButtonName") {
          ...PropertyFragment
        }
        hideWelcomeModal: property(alias: "hideWelcomeModal") {
          ...PropertyFragment
        }
        showSurveyModal: property(alias: "showSurveyModal") {
          ...PropertyFragment
        }
        satisfactionQuestion: property(alias: "satisfactionQuestion") {
          ...PropertyFragment
        }
        poorReviewQuestion: property(alias: "poorReviewQuestion") {
          ...PropertyFragment
        }
        goodReviewQuestion: property(alias: "goodReviewQuestion") {
          ...PropertyFragment
        }
        submitButtonText: property(alias: "submitButtonText") {
          ...PropertyFragment
        }
      }
    }
  `
  )

  const pageProperties = readPageProperties(data);
  return (
    <>
      <Slice alias="header" />
      <main className="layout">

        <div className="content">{children}</div>


        {
          pageProperties?.cookiePolicy === "true" ?
            <></>
            :
            <CookieConsent
              enableDeclineButton={false}
              location="bottom"
              buttonText="Accept cookies"
              declineButtonText="Decline"
              cookieName="gatsby-gdpr-google-analytics"
            >
              <div
                dangerouslySetInnerHTML={{ __html: pageProperties?.cookieDescription }}
              />
            </CookieConsent>
        }

        <WelcomeModal pageProperties={pageProperties} />
        {
          pageProperties?.showSurveyModal == "true" && <SurveyModal pageProperties={pageProperties} />
        }
        <Slice alias="footer" />
      </main>
    </>
  )
}
